<script setup>
// import { ArrowDown } from '@element-plus/icons-vue'

const props = defineProps({
  data: Object,
  label: String,
  numTags: {
    type: Number,
    default: 1,
  },
  itemKey: {
    type: String,
    default: 'id',
  },
})
</script>

<template>
  <div class="radius" :style="{
    borderRadius:
      `var(--el-border-radius-base)`
  }">
    <el-dropdown trigger="click" >
      <div v-if="data" class="tags">
        <template v-for="i in data.slice(0, props.numTags)" :key="i[props.itemKey]">
          <el-tag class="tag" v-if="!!$slots.tag">
            <slot name="tag" :item="i"></slot>
          </el-tag>
          <el-tag class="tag" v-else>
            {{ i[props.label] }}
          </el-tag>
        </template>
        <el-tag v-if="data.length > numTags" class="tag">
          + {{ data.length - numTags }}
        </el-tag>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-for="i in data" :key="i[props.itemKey]">
            <template v-if="!!$slots.dropdown">
              <slot name="dropdown" :item="i"></slot>
            </template>
            <span v-else>
              {{ i[props.label] }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>


<style scoped>
.tag {
  color: #cfd3dc;
  background: rgba(76, 175, 180, 0.3);
}

.radius {
  border: 1px solid var(--el-border-color);
  border-radius: 0;
  padding: 3px;
}

.tags {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>