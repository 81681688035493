<template>
  <div>
<!--    <div class="gva-search-box">-->
<!--      <el-form :inline="true" :model="searchInfo" class="demo-form-inline">-->
<!--        <el-form-item>-->
<!--          <el-button size="small" type="primary" icon="search" @click="onSubmit">查询</el-button>-->
<!--          <el-button size="small" icon="refresh" @click="onReset">重置</el-button>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </div>-->
    <div class="gva-table-box">
      <div class="gva-btn-list">
        <el-button size="small" type="primary" icon="plus" @click="addCompanyFunc">新增</el-button>
        <el-popover v-model:visible="deleteVisible" placement="top" width="160">
          <p>确定要删除吗？</p>
          <div style="text-align: right; margin-top: 8px;">
            <el-button size="small" text @click="deleteVisible = false">取消</el-button>
            <el-button size="small" type="primary" @click="onDelete">确定</el-button>
          </div>
          <template #reference>
            <el-button icon="delete" size="small" style="margin-left: 10px;" :disabled="!multipleSelection.length">删除
            </el-button>
          </template>
        </el-popover>
      </div>
      <el-table ref="multipleTable" style="width: 100%" tooltip-effect="dark" :data="tableData" row-key="id"
        @selection-change="handleSelectionChange" max-height="800px">
        <el-table-column type="selection" width="55" />
        <el-table-column label="id" prop="id" width="40" />
        <el-table-column label="公司名称" prop="name" min-width="120" />
        <el-table-column label="公司代号" prop="abbreviation" />
        <el-table-column label="公司类型" min-width="100">
          <template #default="scope">
            {{ getTypeString(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column label="创建日期" prop="createdat" min-width="100">
          <template #default="scope">{{ formatDate(scope.row.createdat) }}</template>
        </el-table-column>
        <el-table-column label="拥有线路" min-width="200">
          <template #default="scope">
            <DropdownWithTags :data="scope.row.cables" :num-tags="1">
              <template #tag="{item}">
                <el-link @click="jump2cable(item.id)">
                  <div style="display: flex; align-items: center">
                    <box-icon :id="item.id" size="xs"/>
                    {{ item.name }}
                  </div>
                </el-link>
              </template>
              <template #dropdown="{item}">
                <el-link @click="jump2cable(item.id)">
                  <div style="display: flex; align-items: center">
                    <box-icon :id="item.id" size="xs"/>
                    {{ item.name }}
                  </div>
                </el-link>
              </template>
            </DropdownWithTags>
          </template>
        </el-table-column>
        <el-table-column label="下属账号" min-width="150">
          <template #default="scope">
            <DropdownWithTags :data="scope.row.users" label="userName" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" min-width="100">
          <template #default="scope">
            <el-button plain icon="edit" type="primary" size="small" class="table-button" @click="updateCompanyFunc(scope.row)">
              变更</el-button>
            <el-button plain icon="delete" type="warning" size="small" @click="deleteRow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="gva-pagination">
        <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page="page" :page-size="pageSize"
          :page-sizes="[15, 30, 50, 100]" :total="total" @current-change="handleCurrentChange"
          @size-change="handleSizeChange" />
      </div>
    </div>
    <el-dialog v-model="dialogFormVisible" :before-close="closeDialog" :show-close="false">
      <el-form ref="formRef" :model="formData" label-position="right" label-width="80px" :rules="formRules">
        <el-form-item label="公司名" prop="name">
          <el-input v-model.trim="formData.name" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="公司代号" prop="abbreviation">
          <el-input v-model.trim="formData.abbreviation" clearable placeholder="请输入" />
        </el-form-item>
        <!-- <el-form-item label="地址:">
          <el-input v-model.trim="formData.address" clearable placeholder="请输入" />
        </el-form-item> -->
        <el-form-item label="公司类型" prop="typeArray">
          <el-checkbox-group v-model="formData.typeArray">
            <el-checkbox :label="1">运维厂商</el-checkbox>
            <el-checkbox :label="2">生产厂家</el-checkbox>
            <el-checkbox :label="4">用户公司</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <el-form-item>
        <div class="dialog-footer">
          <el-button size="small" @click="closeDialog">取 消</el-button>
          <el-button size="small" type="primary" @click="enterDialog(formRef)">确 定</el-button>
        </div>
      </el-form-item>
    </el-dialog>
  </div>

</template>


<script setup>
import {
  deleteCompanyByIds,
  getCompany,
  addCompany,
  removeCompany,
  renewCompany,
} from '@/api/company'

import { parseTypeCode } from '@/utils/companyCode'
import { formatDate } from '@/utils/format'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref } from 'vue'

import DropdownWithTags from '../components/DropdownWithTags'
import {store} from "@/store";
import {useRouter} from "vue-router";
const router = useRouter()

const formRules = {
  name: [
    { required: true, message: '请输入公司名' }
  ],
  typeArray: [
    { type: 'array', },
    { required: true, message: '请至少为公司设置一种类型', trigger: 'blur' }
  ],
}

const formDataOriginal = {
  id: '',
  // uuid: '',
  name: '',
  // address: '',
  type: 0,
  typeArray: [],
  // modelString: '',
  // comment: '',
  // deleted: false,
}

// 自动化生成的字典（可能为空）以及字段
const formData = ref(formDataOriginal)

// =========== 表格控制部分 ===========
const page = ref(1)
const total = ref(0)
const pageSize = ref(15)
const tableData = ref([])
const searchInfo = ref({})
const formRef = ref(null)


// 重置
const onReset = () => {
  searchInfo.value = {}
}

// 搜索
const onSubmit = () => {
  page.value = 1
  pageSize.value = 15
  if (searchInfo.value.deleted === "") {
    searchInfo.value.deleted = null
  }
  getTableData()
}

// 分页
const handleSizeChange = (val) => {
  pageSize.value = val
  getTableData()
}

// 修改页面容量
const handleCurrentChange = (val) => {
  // console.log('***before', page.value)
  page.value = val
  getTableData()
  // console.log('*****after', page.value)
}

// 查询
const getTableData = async () => {
  // const table = await getCompanyList({ page_num: page.value, page_size: pageSize.value, ...searchInfo.value })
  const table = await getCompany({ ext: true, pageSize: pageSize.value, page: page.value })
  if (table.code === 0) {
    tableData.value = table.data.list
    // setTableData()
    total.value = table.data.total
    page.value = table.data.page
    pageSize.value = table.data.pageSize
  }
}

getTableData()

// ============== 表格控制部分结束 ===============

// 多选数据
const multipleSelection = ref([])
// 多选
const handleSelectionChange = (val) => {
  multipleSelection.value = val
}

// 删除行
const deleteRow = (row) => {
  ElMessageBox.confirm('确定要删除吗?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    deleteCompanyFunc(row)
  })
}


// 批量删除控制标记
const deleteVisible = ref(false)

// 多选删除
const onDelete = async () => {
  const ids = []
  if (multipleSelection.value.length === 0) {
    ElMessage({
      type: 'warning',
      message: '请选择要删除的数据'
    })
    return
  }
  multipleSelection.value &&
    multipleSelection.value.map(item => {
      ids.push(item.id)
    })
  const res = await deleteCompanyByIds({ ids })
  if (res.code === 0) {
    ElMessage({
      type: 'success',
      message: '删除成功'
    })
    if (tableData.value.length === ids.length && page.value > 1) {
      page.value--
    }
    deleteVisible.value = false
    getTableData()
  }
}

// 行为控制标记（弹窗内部需要增还是改）
const type = ref('')

// 新增行
const addCompanyFunc = async (row) => {
  formData.value = formDataOriginal
  dialogFormVisible.value = true
}


// 更新行
const updateCompanyFunc = async (row) => {
  type.value = 'update'
  var _row = { ...row }
  delete _row.createdat
  delete _row.updatedat
  delete _row.uuid
  formData.value = _row
  formData.value.typeArray = parseTypeCode(formData.value.type)
  dialogFormVisible.value = true
}


// 删除行
const deleteCompanyFunc = async (row) => {
  const res = await removeCompany(row.id)
  if (res.code === 0) {
    ElMessage({
      type: 'success',
      message: '删除成功'
    })
    if (tableData.value.length === 1 && page.value > 1) {
      page.value--
    }
    getTableData()
  }
}

// 弹窗控制标记
const dialogFormVisible = ref(false)

// 打开弹窗
const openDialog = () => {
  type.value = 'create'
  dialogFormVisible.value = true
}

// 关闭弹窗
const closeDialog = () => {
  dialogFormVisible.value = false
}
// 弹窗确定
// const enterDialog = async () => {
//   console.log("formData:", formData)
//   service({
//     url: "/api/v2/company/" + type.value,
//     method: "post",
//     data: formData.value,
//   }).catch((e) => {
//     console.log(e);
//   }).then((res) => {
//     if (res.code === 0) {
//       ElMessage({
//         type: 'success',
//         message: '创建/更改成功'
//       })
//       closeDialog()
//       getTableData()
//     }
//   }).catch((e) => {
//     console.log(e);
//   })
// }


const sum = function (arr) {
  if (arr === undefined) {
    return 0
  }
  return arr.reduce((prevSum, num) => prevSum + num, 0)
}


const enterDialog = async (formEl) => {
  formEl.validate((valid, fields) => {
    if (!valid) {
      alert('请检查您的输入')
      return
    } else {
      var sentRequest
      formData.value.type = sum(formData.value.typeArray)
      delete formData.value.typeArray
      if (formData.value.id) {
        sentRequest = renewCompany(formData.value)
      } else {
        sentRequest = addCompany(formData.value)
      }
      sentRequest.catch((e) => {
        console.log(e);
      }).then((res) => {
        if (res.code === 0) {
          ElMessage({
            type: 'success',
            message: '创建/更改成功'
          })
          closeDialog()
          getTableData()
        }
      }).catch((e) => {
        console.log(e);
      })
    }
  })
}

function getTypeString(code) {
  let array = parseTypeCode(code),
      r = []
  for (let i of array) {
    if (i === 1) {
      r.push('运维厂商')
    } else if (i === 2) {
      r.push('生产厂家')
    } else if (i === 4) {
      r.push('用户公司')
    }
  }
  return r.join(' ')
}

function jump2cable(cableId) {
  store.commit('cables/setCurrent', cableId)
  router.push('../cablecenter')
}

</script>
